// src/components/Orders/ImagePopupModal.tsx

import React from 'react';
import Modal from 'react-modal';
import { FrameType, FRAME_PRESETS } from '../../services/printingService';
import './ImagePopupModal.css';

interface ImagePopupModalProps {
  isOpen: boolean;
  onRequestClose: () => void;
  imageUrl: string;
  imageName: string;
  orderId: string;
  onDelete: () => void;
  onPrint: () => void;
  frameType: FrameType;
  setFrameType: React.Dispatch<React.SetStateAction<FrameType | null>>;
  isGreyscale: boolean;
  setIsGreyscale: React.Dispatch<React.SetStateAction<boolean>>;
  isPrinting: boolean;
}

const ImagePopupModal: React.FC<ImagePopupModalProps> = ({
  isOpen,
  onRequestClose,
  imageUrl,
  imageName,
  onDelete,
  onPrint,
  frameType,
  setFrameType,
  isGreyscale,
  setIsGreyscale,
  isPrinting,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Image Actions"
      ariaHideApp={false}
      className="image-popup-modal"
      overlayClassName="image-popup-overlay"
    >
      <img src={imageUrl} alt={imageName} className="popup-image" />
      <p>{imageName}</p>
      <select
        value={frameType}
        onChange={(e) => setFrameType(e.target.value as FrameType)}
        className="image-frame-select"
      >
        {Object.entries(FRAME_PRESETS).map(([key, value]) => {
          const frameKey = key as FrameType;
          return (
            <option key={frameKey} value={frameKey}>
              {value.hebrew} ({frameKey})
            </option>
          );
        })}
      </select>
      <label className="greyscale-option">
        <input
          type="checkbox"
          checked={isGreyscale}
          onChange={() => setIsGreyscale(!isGreyscale)}
        />
        Print in Greyscale
      </label>
      <div className="popup-actions">
        <button
          onClick={onPrint}
          disabled={isPrinting}
          className="popup-button"
        >
          {isPrinting ? 'Printing...' : 'Print'}
        </button>
        <button onClick={onDelete} className="popup-button delete-button">
          Delete
        </button>
        <button onClick={onRequestClose} className="popup-button close-button">
          Close
        </button>
      </div>
    </Modal>
  );
};

export default ImagePopupModal;
