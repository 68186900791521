import React, { useState, useCallback, useEffect } from 'react';
import Modal from 'react-modal';
import axios, { AxiosError } from 'axios';
import './OrderImagesModal.css';
import CropModal from './CropModal';
import ImagePopupModal from './ImagePopupModal';
import ConfirmationPopup from './ConfirmationPopup';
import {
  printOrder,
  FrameType,
  FRAME_PRESETS,
  printMultipleImages,
  printSingleImage,
} from '../../services/printingService';
import { useAuth0 } from '@auth0/auth0-react';
import { printOrderTxt } from '../../services/printingService';

interface OrderImagesModalProps {
  isOpen: boolean;
  onRequestClose: () => void;
  orderId: string;
}

interface ImageData {
  name: string;
  url: string;
}

const API_BASE_URL = process.env.REACT_APP_API_URL || 'http://localhost:5000';

const OrderImagesModal: React.FC<OrderImagesModalProps> = ({ isOpen, onRequestClose, orderId }) => {
  const { getAccessTokenSilently } = useAuth0();
  const [isPrintingOrderTxt, setIsPrintingOrderTxt] = useState(false);
  const [images, setImages] = useState<ImageData[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [cropModalOpen, setCropModalOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isPrinting, setIsPrinting] = useState(false);
  const [frameType, setFrameType] = useState<FrameType | null>(null);
  const [originalFrameType, setOriginalFrameType] = useState<FrameType>('Classic');
  const [selectedImage, setSelectedImage] = useState<ImageData | null>(null);
  const [isGreyscale, setIsGreyscale] = useState(false);
  const [selectedOrderPreset, setSelectedOrderPreset] = useState<FrameType | null>(null);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [selectedImagesForConfirmation, setSelectedImagesForConfirmation] = useState<{
    [imageName: string]: { frameType: FrameType; isGreyscale: boolean; url: string };
  }>({});
  const [globalFrameType, setGlobalFrameType] = useState<FrameType | null>(null);

  const handlePrintOrderTxt = async () => {
    setIsPrintingOrderTxt(true);
    try {
      const accessToken = await getAccessTokenSilently();
      await printOrderTxt(orderId, accessToken);
      console.log('Print job sent for order.txt');
    } catch (error) {
      console.error('Error printing order.txt:', error);
      if (error instanceof AxiosError) {
        if (error.response) {
          setError(`Failed to print order.txt: ${error.response.data.error || error.message}`);
        } else if (error.request) {
          setError('Failed to print order.txt: No response received from server');
        } else {
          setError(`Failed to print order.txt: ${error.message}`);
        }
      } else if (error instanceof Error) {
        setError(`Failed to print order.txt: ${error.message}`);
      } else {
        setError('Failed to print order.txt: Unknown error');
      }
    } finally {
      setIsPrintingOrderTxt(false);
    }
  };
  
  const fetchImages = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const accessToken = await getAccessTokenSilently();
      const response = await axios.get(`${API_BASE_URL}/images/${orderId}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      setImages(response.data.imageData || []);
    } catch (error) {
      console.error('Error fetching images:', error);
      setError('Failed to fetch images');
    } finally {
      setLoading(false);
    }
  }, [orderId, getAccessTokenSilently]);

  const fetchFrameType = useCallback(async () => {
    try {
      const accessToken = await getAccessTokenSilently();
      const response = await axios.get(`${API_BASE_URL}/images/${orderId}/frame-type`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      setFrameType(response.data.frameType);
      setOriginalFrameType(response.data.frameType);
      setSelectedOrderPreset(response.data.frameType);
      setGlobalFrameType(response.data.frameType);
    } catch (error) {
      console.error('Error fetching frame type:', error);
      setError('Failed to fetch frame type');
    }
  }, [orderId, getAccessTokenSilently]);

  useEffect(() => {
    if (isOpen) {
      fetchImages();
      fetchFrameType();
    }
  }, [isOpen, fetchImages, fetchFrameType]);

  const handleImageUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setSelectedFile(file);
      setCropModalOpen(true);
    }
  };

  const handleCropComplete = async (croppedImageBlob: Blob) => {
    if (!selectedFile) return;

    try {
      const accessToken = await getAccessTokenSilently();
      setUploadProgress(0);
      const formData = new FormData();
      formData.append('image', croppedImageBlob, selectedFile.name);

      console.log('Uploading image:', selectedFile.name);

      await axios.post(`${API_BASE_URL}/images/${orderId}/upload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${accessToken}`,
        },
        onUploadProgress: (progressEvent) => {
          if (progressEvent.total) {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setUploadProgress(percentCompleted);
          }
        },
      });

      setCropModalOpen(false);
      setSelectedFile(null);
      setUploadProgress(0);
      fetchImages();
    } catch (error) {
      console.error('Error uploading cropped image:', error);
      if (axios.isAxiosError(error) && error.response) {
        console.error('Server response:', error.response.data);
      }
      setError('Failed to upload cropped image');
    }
  };

  const handleImageClick = (image: ImageData) => {
    setSelectedImage(image);
    setIsGreyscale(false);
  };

  const handleShowConfirmation = () => {
    const allImages = images.reduce((acc, image) => {
      acc[image.name] = { 
        frameType: frameType || 'Classic', 
        isGreyscale: false,
        url: image.url
      };
      return acc;
    }, {} as { [imageName: string]: { frameType: FrameType; isGreyscale: boolean; url: string } });
    
    setSelectedImagesForConfirmation(allImages);
    setGlobalFrameType(frameType || 'Classic');
    setShowConfirmation(true);
  };

  const handleConfirmPrint = async () => {
    setIsPrinting(true);
    try {
      const accessToken = await getAccessTokenSilently();
      const imagesToPrint = Object.entries(selectedImagesForConfirmation).map(
        ([imageName, { frameType, isGreyscale }]) => ({
          imageName,
          frameType,
          isGreyscale,
        })
      );
      await printMultipleImages(orderId, imagesToPrint, accessToken);
      console.log('Print jobs sent for selected images');
      setShowConfirmation(false);
    } catch (error) {
      console.error('Error printing selected images:', error);
      setError('Failed to print selected images');
    } finally {
      setIsPrinting(false);
    }
  };

  const handleDeselectImage = (imageName: string) => {
    const updatedSelection = { ...selectedImagesForConfirmation };
    delete updatedSelection[imageName];
    setSelectedImagesForConfirmation(updatedSelection);
  };

  const handleChangeIndividualPreset = (imageName: string, newFrameType: FrameType) => {
    setSelectedImagesForConfirmation(prev => ({
      ...prev,
      [imageName]: { ...prev[imageName], frameType: newFrameType },
    }));
  };

  const handleChangeGlobalPreset = (newFrameType: FrameType) => {
    setGlobalFrameType(newFrameType);
    setSelectedImagesForConfirmation(prev => 
      Object.entries(prev).reduce((acc, [imageName, imageData]) => {
        acc[imageName] = { ...imageData, frameType: newFrameType };
        return acc;
      }, {} as typeof prev)
    );
  };

  const handleChangeGreyscale = (imageName: string) => {
    setSelectedImagesForConfirmation(prev => ({
      ...prev,
      [imageName]: { ...prev[imageName], isGreyscale: !prev[imageName].isGreyscale },
    }));
  };

  const handleDeleteImage = async () => {
    if (!selectedImage) return;

    try {
      const accessToken = await getAccessTokenSilently();
      await axios.delete(`${API_BASE_URL}/images/${orderId}/${selectedImage.name}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      setSelectedImage(null);
      fetchImages();
    } catch (error) {
      console.error('Error deleting image:', error);
      setError('Failed to delete image');
    }
  };

  const handlePrintSingleImage = async () => {
    if (!selectedImage) return;
    setIsPrinting(true);
    try {
      const accessToken = await getAccessTokenSilently();
      await printSingleImage(
        orderId,
        selectedImage.name,
        frameType || 'Classic',
        isGreyscale,
        accessToken
      );
      console.log('Print job sent for image:', selectedImage.name);
      setSelectedImage(null);
    } catch (error) {
      console.error('Error printing single image:', error);
      setError('Failed to print image');
    } finally {
      setIsPrinting(false);
    }
  };

  const handleClosePopup = () => {
    setSelectedImage(null);
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Order Images"
      ariaHideApp={false}
    >
      <h2>Images for Order {orderId}</h2>
      {frameType && (
        <p className="frame-type-display">
          Original Frame Type: {FRAME_PRESETS[frameType].hebrew} ({frameType})
        </p>
      )}
      <input
        type="file"
        accept="image/*"
        onChange={handleImageUpload}
        disabled={cropModalOpen}
      />
      {loading && <p>Loading images...</p>}
      {error && <p className="error-message">{error}</p>}
      {uploadProgress > 0 && uploadProgress < 100 && <p>Uploading: {uploadProgress}%</p>}

      {!loading && !error && (
        <div className="image-grid">
          {images.length > 0 ? (
            images.map((image, index) => (
              <div
                key={index}
                className="image-item"
                onClick={() => handleImageClick(image)}
              >
                <img
                  src={image.url}
                  alt={`Order ${orderId} - Image ${index + 1}`}
                />
                <p>{image.name}</p>
              </div>
            ))
          ) : (
            <p>No images found for this order.</p>
          )}
        </div>
      )}

      <div className="order-print-controls">
        <button
          onClick={handleShowConfirmation}
          disabled={isPrinting || loading || !!error}
          className="print-order-button"
        >
          {isPrinting ? 'Printing...' : 'Print Order'}
        </button>
        <button
          onClick={handlePrintOrderTxt}
          disabled={isPrintingOrderTxt || loading || !!error}
          className="print-order-txt-button"
        >
          {isPrintingOrderTxt ? 'Printing...' : 'Print Order.txt'}
        </button>
      </div>
      <button onClick={onRequestClose} className="close-button">
        Close
      </button>

      {cropModalOpen && selectedFile && (
        <CropModal
          file={selectedFile}
          onComplete={handleCropComplete}
          onCancel={() => {
            setCropModalOpen(false);
            setSelectedFile(null);
          }}
        />
      )}

      {selectedImage && (
        <ImagePopupModal
          isOpen={!!selectedImage}
          onRequestClose={handleClosePopup}
          imageUrl={selectedImage.url}
          imageName={selectedImage.name}
          onDelete={handleDeleteImage}
          orderId={orderId}
          frameType={frameType || 'Classic'}
          onPrint={handlePrintSingleImage}
          isPrinting={isPrinting}
          setFrameType={setFrameType}
          isGreyscale={isGreyscale}
          setIsGreyscale={setIsGreyscale}
        />
      )}

      <ConfirmationPopup
        isOpen={showConfirmation}
        onRequestClose={() => setShowConfirmation(false)}
        selectedImages={selectedImagesForConfirmation}
        onConfirm={handleConfirmPrint}
        onDeselectImage={handleDeselectImage}
        onChangeIndividualPreset={handleChangeIndividualPreset}
        onChangeGreyscale={handleChangeGreyscale}
        globalFrameType={globalFrameType}
        onChangeGlobalPreset={handleChangeGlobalPreset}
        isPrinting={isPrinting}
        shouldPrintOrderTxt={isPrintingOrderTxt} setShouldPrintOrderTxt={function (value: React.SetStateAction<boolean>): void {
          throw new Error('Function not implemented.');
        } }      />
    </Modal>
  );
};

export default OrderImagesModal;