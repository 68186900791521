// File: src/services/printingService.ts

import axios, { AxiosError } from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_URL || 'http://localhost:5000';

export const FRAME_PRESETS = {
  "Classic": {
    "hebrew": "קלאסי",
    "label": "8x8 | Classic/Authentic | A4 | Luster",
    "print_setting": {
      "media_size": "ms_a4",
      "media_type": "mt_photopaper",
      "color_mode": "color",
      "print_quality": "high",
      "source": "front2",
    },
    "layout": {
      "width": 6.00,
      "height": 6.00,
      "left": 1.00,
      "top": 1.00
    }
  },
  "Authentic": {
    "hebrew": "אותנטי",
    "label": "8x8 | Classic/Authentic | A4 | Luster",
    "print_setting": {
      "media_size": "ms_a4",
      "media_type": "mt_photopaper",
      "color_mode": "color",
      "print_quality": "high",
      "source": "front2",
    },
    "layout": {
      "width": 6.00,
      "height": 6.00,
      "left": 1.00,
      "top": 1.00
    }
  },
  "Bold": {
    "hebrew": "נועז",
    "label": "8x8 | Clean/Bold | A4 | Luster",
    "print_setting": {
      "media_size": "ms_a4",
      "media_type": "mt_photopaper",
      "color_mode": "color",
      "print_quality": "high",
      "source": "front2",
    },
    "layout": {
      "width": 7.99,
      "height": 7.99,
      "left": 0.01,
      "top": 0.01
    }
  },
  "Clean": {
    "hebrew": "נקי",
    "label": "8x8 | Clean/Bold | A4 | Luster",
    "print_setting": {
      "media_size": "ms_a4",
      "media_type": "mt_photopaper",
      "color_mode": "color",
      "print_quality": "high",
      "source": "front2",
    },
    "layout": {
      "width": 7.99,
      "height": 7.99,
      "left": 0.01,
      "top": 0.01
    }
  }
};

export type FrameType = keyof typeof FRAME_PRESETS;

export const printOrder = async (orderId: string, accessToken: string, frameType: FrameType) => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/images/print-order/${orderId}`,
      { frameType },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error printing order:', error);
    throw error;
  }
};

export const printSingleImage = async (
  orderId: string,
  imageName: string,
  frameType: FrameType,
  isGreyscale: boolean,
  accessToken: string
) => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/images/${orderId}/print/${imageName}`,
      {
        frameType,
        isGreyscale,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error printing single image:', error);
    throw error;
  }
};

export const printMultipleImages = async (
  orderId: string,
  imagesToPrint: { imageName: string; frameType: FrameType; isGreyscale: boolean }[],
  accessToken: string
) => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/images/${orderId}/print-multiple`,
      {
        images: imagesToPrint,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error printing multiple images:', error);
    throw error;
  }
};

export const printOrderTxt = async (orderId: string, accessToken: string) => {
  try {
    console.log(`Sending print request for order.txt of order ${orderId}`);
    const response = await axios.post(
      `${API_BASE_URL}/images/${orderId}/print-order-txt`,
      {},
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    console.log('Print job response:', response.data);
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error('Axios error printing order.txt:', error.response?.data || error.message);
    } else {
      console.error('Error printing order.txt:', error);
    }
    throw error; // Re-throw the error to be handled by the caller
  }
};