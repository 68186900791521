const API_URL = process.env.REACT_APP_API_URL || 'https://dev.blends.co.il/api';

interface Order {
  orderId: string;
  date: string;
  customer: string;
  status: string;
  frame: string;
  items: string;
  phoneNumber: string;
  city: string;
  notes: string;
}

export const fetchOrders = async (accessToken: string): Promise<Order[]> => {
  try {
    console.log('Fetching orders from:', `${API_URL}/orders`);
    const response = await fetch(`${API_URL}/orders`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    if (!response.ok) {
      const errorText = await response.text();
      throw new Error(`HTTP error! status: ${response.status}, message: ${errorText}`);
    }
    const contentType = response.headers.get("content-type");
    if (contentType && contentType.indexOf("application/json") !== -1) {
      const data: Order[] = await response.json();
      console.log('Fetched orders:', data);
      return data;
    } else {
      const text = await response.text();
      console.error('Unexpected response:', text);
      throw new Error('Server did not return JSON');
    }
  } catch (error) {
    console.error('Error fetching orders:', error);
    throw error;
  }
};