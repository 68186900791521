// src/components/Orders/CreateOrderModal.tsx

import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import CropModal from './CropModal';
import axios from 'axios';
import { FrameType, FRAME_PRESETS } from '../../services/printingService';
import './CreateOrderModal.css';
import { useAuth0 } from '@auth0/auth0-react';

interface CreateOrderModalProps {
 isOpen: boolean;
 onRequestClose: () => void;
}

interface OrderFormData {
 customerName: string;
 phoneNumber: string;
 address: string;
 email: string;
 city: string;
 comments: string;
 amount: number;
 pricePerFrame: number;
 frameType: FrameType;
 images: { blob: Blob, frameType: FrameType }[];
 totalPrice: number;
}

const API_BASE_URL = process.env.REACT_APP_API_URL || 'http://localhost:5000';

const CreateOrderModal: React.FC<CreateOrderModalProps> = ({ isOpen, onRequestClose }) => {
 const { getAccessTokenSilently } = useAuth0();
 const [orderId, setOrderId] = useState<string | null>(null);
 const [formData, setFormData] = useState<OrderFormData>({
   customerName: '',
   phoneNumber: '',
   address: '',
   email: '',
   city: '',
   comments: '',
   amount: 0,
   pricePerFrame: 0,
   frameType: 'Classic',
   images: [],
   totalPrice: 0
 });

 const [showCropModal, setShowCropModal] = useState(false);
 const [currentFile, setCurrentFile] = useState<File | null>(null);
 const [error, setError] = useState<string | null>(null);
 const [uploadProgress, setUploadProgress] = useState(0);
 const [successMessage, setSuccessMessage] = useState<string | null>(null);

 useEffect(() => {
   if (isOpen && !orderId) {
     const initOrderId = async () => {
       try {
         const accessToken = await getAccessTokenSilently();
         const response = await axios.get(`${API_BASE_URL}/orders/latest`, {
           headers: {
             Authorization: `Bearer ${accessToken}`,
           },
         });
         const latestId = response.data.latestId;
         const newOrderId = latestId ? parseInt(latestId) + 1 : 534422000;
         setOrderId(newOrderId.toString());
       } catch (error) {
         console.error('Error initializing order ID:', error);
         if (axios.isAxiosError(error)) {
           console.error('Response:', error.response?.data);
         }
         setError('Failed to initialize order');
       }
     };
     initOrderId();
   }
 }, [isOpen, getAccessTokenSilently]);

 const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
   const { name, value } = e.target;
   setFormData(prev => ({
     ...prev,
     [name]: name === 'amount' || name === 'pricePerFrame' || name === 'totalPrice' ? parseFloat(value) : value
   }));
 };

 useEffect(() => {
   setFormData(prev => ({
     ...prev,
     totalPrice: prev.amount * prev.pricePerFrame
   }));
 }, [formData.amount, formData.pricePerFrame]);

 const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
   const file = event.target.files?.[0];
   if (file) {
     setCurrentFile(file);
     setShowCropModal(true);
   }
 };

 const handleCropComplete = async (croppedImageBlob: Blob) => {
   if (!currentFile) return;

   try {
     setFormData(prev => ({
       ...prev,
       images: [...prev.images, { blob: croppedImageBlob, frameType: prev.frameType }]
     }));
     setShowCropModal(false);
     setCurrentFile(null);
   } catch (error) {
     console.error('Error handling cropped image:', error);
     setError('Failed to process image');
   }
 };

 const handleSubmit = async () => {
   if (!orderId) return;

   try {
     const accessToken = await getAccessTokenSilently();
     const orderData = {
       ...formData,
       orderId,
       finalPrice: formData.totalPrice
     };

     const orderResponse = await axios.post(`${API_BASE_URL}/orders/create`, orderData, {
       headers: {
         Authorization: `Bearer ${accessToken}`,
       },
     });

     // Upload images to S3
     for (let i = 0; i < formData.images.length; i++) {
       const image = formData.images[i];
       const formDataImage = new FormData();
       formDataImage.append('image', image.blob, `${orderId}-${i}.png`);

       await axios.post(`${API_BASE_URL}/orders/${orderId}/upload`, formDataImage, {
         headers: {
           Authorization: `Bearer ${accessToken}`,
           'Content-Type': 'multipart/form-data',
         },
         onUploadProgress: (progressEvent) => {
           if (progressEvent.total) {
             const percentCompleted = Math.round(
               (progressEvent.loaded * 100) / progressEvent.total
             );
             setUploadProgress(percentCompleted);
           }
         },
       });
     }

     setSuccessMessage('Order created successfully');
     setTimeout(() => {
       setSuccessMessage(null);
       onRequestClose();
     }, 2000);
   } catch (error) {
     console.error('Error creating order:', error);
     if (axios.isAxiosError(error) && error.response?.data) {
       console.error('Server error details:', error.response.data);
       setError(`Failed to create order: ${error.response.data.error}`);
     } else {
       setError('Failed to create order');
     }
   }
 };

 return (
   <Modal
     isOpen={isOpen}
     onRequestClose={onRequestClose}
     contentLabel="Create New Order"
     className="create-order-modal"
     overlayClassName="create-order-overlay"
     ariaHideApp={false}
   >
     <div className="create-order-content">
       <h2>Create New Order {orderId && `(#${orderId})`}</h2>

       <div className="form-grid">
         <div className="form-group">
           <label>Customer Name:</label>
           <input
             type="text"
             name="customerName"
             value={formData.customerName}
             onChange={handleInputChange}
           />
         </div>

         <div className="form-group">
           <label>Phone Number:</label>
           <input
             type="tel"
             name="phoneNumber"
             value={formData.phoneNumber}
             onChange={handleInputChange}
           />
         </div>

         <div className="form-group">
           <label>Address:</label>
           <input
             type="text"
             name="address"
             value={formData.address}
             onChange={handleInputChange}
           />
         </div>

         <div className="form-group">
           <label>Email:</label>
           <input
             type="email"
             name="email"
             value={formData.email}
             onChange={handleInputChange}
           />
         </div>

         <div className="form-group">
           <label>City:</label>
           <input
             type="text"
             name="city"
             value={formData.city}
             onChange={handleInputChange}
           />
         </div>

         <div className="form-group">
           <label>Frame Type:</label>
           <select
             value={formData.frameType}
             onChange={(e) => setFormData(prev => ({
               ...prev,
               frameType: e.target.value as FrameType
             }))}
           >
             {Object.entries(FRAME_PRESETS).map(([key, value]) => (
               <option key={key} value={key}>
                 {value.hebrew} ({key})
               </option>
             ))}
           </select>
         </div>

         <div className="form-group">
           <label>Amount:</label>
           <input
             type="number"
             name="amount"
             value={formData.amount}
             onChange={handleInputChange}
           />
         </div>

         <div className="form-group">
           <label>Price per Frame:</label>
           <input
             type="number"
             name="pricePerFrame"
             value={formData.pricePerFrame}
             onChange={handleInputChange}
           />
         </div>

         <div className="form-group">
           <label>Total Price:</label>
           <input
             type="number"
             name="totalPrice"
             value={formData.totalPrice}
             onChange={handleInputChange}
           />
         </div>

         <div className="form-group">
           <label>Comments:</label>
           <textarea
             name="comments"
             value={formData.comments}
             onChange={handleInputChange}
           />
         </div>
       </div>

       <div className="images-section">
         <h3>Upload Images</h3>
         <input
           type="file"
           accept="image/*"
           onChange={handleFileSelect}
           disabled={showCropModal}
         />
         {uploadProgress > 0 && uploadProgress < 100 && <p>Uploading: {uploadProgress}%</p>}
         <div className="uploaded-images">
           {formData.images.map((image, index) => (
             <div key={index} className="image-preview">
               <img src={URL.createObjectURL(image.blob)} alt={`Upload ${index + 1}`} />
             </div>
           ))}
         </div>
       </div>

       {error && <div className="error-message">{error}</div>}
       {successMessage && <div className="success-message">{successMessage}</div>}

       <div className="modal-actions">
         <button onClick={handleSubmit}>Create Order</button>
         <button onClick={onRequestClose}>Cancel</button>
       </div>
     </div>

     {showCropModal && currentFile && (
       <CropModal
         file={currentFile}
         onComplete={handleCropComplete}
         onCancel={() => {
           setShowCropModal(false);
           setCurrentFile(null);
         }}
       />
     )}
   </Modal>
 );
};

export default CreateOrderModal;
