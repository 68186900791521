import React, { useMemo, useState } from 'react';
import './MobileOrderList.css';
import MobileOrderImagesModal from './MobileOrderImagesModal';

interface Order {
  orderId: string;
  date: string;
  customer: string;
  status: string;
  frame: string;
  items: string;
  phoneNumber: string;
  city: string;
}

interface MobileOrderListProps {
  orders: Order[];
}

const MobileOrderList: React.FC<MobileOrderListProps> = ({ orders }) => {
  const [selectedOrderId, setSelectedOrderId] = useState<string | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const getStatusClass = (status: string) => {
    switch (status) {
      case 'מוכן':
        return 'status-ready';
      case 'בוטל':
        return 'status-cancelled';
      case 'הודפס':
        return 'status-printed';
      case 'נקלט':
        return 'status-received';
      default:
        return '';
    }
  };

  const sortedOrders = useMemo(() => {
    return [...orders].sort((a, b) => {
      const dateA = new Date(a.date.split('/').reverse().join('-'));
      const dateB = new Date(b.date.split('/').reverse().join('-'));
      return dateB.getTime() - dateA.getTime(); // Descending order
    });
  }, [orders]);

  const openModal = (orderId: string) => {
    setSelectedOrderId(orderId);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedOrderId(null);
  };

  return (
    <div className="mobile-order-list" >
      {sortedOrders.map(order => (
        <div key={order.orderId} className="mobile-order-item" onClick={() => openModal(order.orderId)}>
          <div className="mobile-order-header" onClick={() => openModal(order.orderId)}>
            <span className="mobile-order-id">Order #{order.orderId}</span>
            <span className={`mobile-status-badge ${getStatusClass(order.status)}`}>
              {order.status}
            </span>
          </div>
          <div className="mobile-order-details">
            <p><strong>Date:</strong> {order.date}</p>
            <p><strong>Customer:</strong> {order.customer}</p>
            <p><strong>Items:</strong> {order.items}</p>
            <p><strong>Frame:</strong> {order.frame}</p>
            <p><strong>Phone:</strong> {order.phoneNumber}</p>
            <p><strong>City:</strong> {order.city}</p>
          </div>
        </div>
      ))}
      {selectedOrderId && (
        <MobileOrderImagesModal
          isOpen={isModalOpen}
          onRequestClose={closeModal}
          orderId={selectedOrderId}
        />
      )}
    </div>
  );
};

export default MobileOrderList;