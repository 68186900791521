import React, { useState, useEffect } from 'react';
import './OrderList.css';
import OrderImagesModal from './OrderImagesModal';

interface Order {
  orderId: string;
  date: string;
  customer: string;
  status: string;
  frame: string;
  items: string;
  phoneNumber: string;
  city: string;
  notes: string;
  price: string;
}

interface OrderListProps {
  orders: Order[];
}

type SortKey = 'orderId' | 'date' | 'items' | 'price';

const OrderList: React.FC<OrderListProps> = ({ orders: initialOrders }) => {
  const [orders, setOrders] = useState<Order[]>([]);
  const [sortConfig, setSortConfig] = useState<{
    key: SortKey;
    direction: 'ascending' | 'descending';
  }>({ key: 'date', direction: 'descending' });
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedOrderId, setSelectedOrderId] = useState<string | null>(null);

  useEffect(() => {
    sortBy('date', 'descending');
  }, [initialOrders]);

  const sortBy = (key: SortKey, initialDirection?: 'ascending' | 'descending') => {
    let direction: 'ascending' | 'descending' = initialDirection || 'ascending';
    if (!initialDirection && sortConfig.key === key) {
      direction = sortConfig.direction === 'ascending' ? 'descending' : 'ascending';
    }
    setSortConfig({ key, direction });

    const sortedOrders = [...initialOrders].sort((a, b) => {
      if (key === 'date') {
        const dateA = new Date(a[key].split('/').reverse().join('-'));
        const dateB = new Date(b[key].split('/').reverse().join('-'));
        return direction === 'ascending'
          ? dateA.getTime() - dateB.getTime()
          : dateB.getTime() - dateA.getTime();
      } else if (key === 'items' || key === 'price') {
        const valueA = parseFloat(a[key]);
        const valueB = parseFloat(b[key]);
        return direction === 'ascending' ? valueA - valueB : valueB - valueA;
      } else {
        if (a[key] < b[key]) return direction === 'ascending' ? -1 : 1;
        if (a[key] > b[key]) return direction === 'ascending' ? 1 : -1;
        return 0;
      }
    });

    setOrders(sortedOrders);
  };

  const getSortIndicator = (key: SortKey) => {
    if (sortConfig.key !== key) {
      return null;
    }
    return sortConfig.direction === 'ascending' ? '▲' : '▼';
  };

  const getStatusClass = (status: string) => {
    switch (status) {
      case 'מוכן':
        return 'status-ready';
      case 'בוטל':
        return 'status-cancelled';
      case 'הודפס':
        return 'status-printed';
      case 'נקלט':
        return 'status-received';
      default:
        return '';
    }
  };

  const openModal = (orderId: string) => {
    setSelectedOrderId(orderId);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setSelectedOrderId(null);
  };

  return (
    <div className="order-list-container">
      <table className="order-list">
        <thead>
          <tr>
            <th onClick={() => sortBy('orderId')}>
              <span className="header-content">
                Order ID
                <span className="sort-indicator">{getSortIndicator('orderId')}</span>
              </span>
            </th>
            <th onClick={() => sortBy('date')}>
              <span className="header-content">
                Date
                <span className="sort-indicator">{getSortIndicator('date')}</span>
              </span>
            </th>
            <th>Customer</th>
            <th onClick={() => sortBy('price')}>
              <span className="header-content">
                Price
                <span className="sort-indicator">{getSortIndicator('price')}</span>
              </span>
            </th>
            <th>Status</th>
            <th>Frame</th>
            <th onClick={() => sortBy('items')}>
              <span className="header-content">
                #
                <span className="sort-indicator">{getSortIndicator('items')}</span>
              </span>
            </th>
            <th>Phone Number</th>
            <th>City</th>
          </tr>
        </thead>
        <tbody>
          {orders.map((order) => (
            <tr key={order.orderId} onClick={() => openModal(order.orderId)}>
              <td data-label="Order ID" className='orderid-column'>
                <span className="order-id-link">
                  {order.orderId}
                </span>
              </td>
              <td data-label="Date" className='date-column'>{order.date}</td>
              <td data-label="Customer" className='customername-column'>{order.customer}</td>
              <td data-label="Price" className='price-column'>
                {order.price ? `${order.price}₪` : ''}
              </td>              
              <td data-label="Status" className='status-column'>
                <span className={`status-badge ${getStatusClass(order.status)}`}>
                  {order.status}
                </span>
              </td>
              <td data-label="Frame" className='frametype-column'>{order.frame}</td>
              <td data-label="Items" className='frameamount-column'>{order.items}</td>
              <td data-label="Phone Number" className='phonenumber-column'>{order.phoneNumber}</td>
              <td data-label="City" className='city-column'>{order.city}</td>
            </tr>
          ))}
        </tbody>
      </table>
      {selectedOrderId && (
        <OrderImagesModal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          orderId={selectedOrderId}
        />
      )}
    </div>
  );
};

export default OrderList;