import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import './Header.css';

const Header = () => {

  return (
    <header className="header">
    </header>
  );
};

export default Header;
