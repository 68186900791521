import React, { useState } from 'react';
import Sidebar from './Sidebar';
import './MobileLayout.css';
import { useAuth0 } from '@auth0/auth0-react';

interface MobileLayoutProps {
  children: React.ReactNode;
}

const MobileLayout: React.FC<MobileLayoutProps> = ({ children }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const { logout } = useAuth0();

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const closeSidebar = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      setIsSidebarOpen(false);
    }
  };

  return (
    <div className="mobile-layout">
      <div className="mobile-header">
        <button className="hamburger-menu" onClick={toggleSidebar}>
          ☰
        </button>
        <img src="https://blends.co.il/assets/images/stk_logo.svg" alt="Logo" />
        <button
          className="logout-button"
          onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}
        >
          Log Out
        </button>
      </div>
      {isSidebarOpen && (
        <div className="sidebar-overlay" onClick={closeSidebar}>
          <div className="sidebar-container">
            <Sidebar />
          </div>
        </div>
      )}
      <div className="mobile-main-content">{children}</div>
    </div>
  );
};

export default MobileLayout;
