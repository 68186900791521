// File: src/components/Orders/OrderSummary.tsx

import React from 'react';
import './OrderSummary.css';

interface OrderSummaryProps {
  title: string;
  count: number;
  icon: string;
  previousCount?: number;
  isCurrency?: boolean;
  showArrow?: boolean;
}

const OrderSummary: React.FC<OrderSummaryProps> = ({
  title,
  count,
  icon,
  previousCount,
  isCurrency,
  showArrow = true,
}) => {
  const formatValue = (value: number) => {
    return isCurrency
      ? value.toLocaleString(undefined, { style: 'currency', currency: 'ILS' })
      : value.toLocaleString();
  };

  let arrowIcon = null;
  if (showArrow && previousCount !== undefined) {
    if (count > previousCount) {
      arrowIcon = <span className="arrow up">▲</span>;
    } else if (count < previousCount) {
      arrowIcon = <span className="arrow down">▼</span>;
    }
    // No arrow if values are equal or showArrow is false
  }

  return (
    <div className="order-summary">
      <div className="icon">{icon}</div>
      <h3>{title}</h3>
      <p className="main-count">
        {formatValue(count)} {arrowIcon}
      </p>
      {previousCount !== undefined && (
        <p className="previous-count">{formatValue(previousCount)}</p>
      )}
    </div>
  );
};

export default OrderSummary;
