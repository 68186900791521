// src/App.tsx

import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Layout from './components/Layout/Layout';
import MobileLayout from './components/Layout/MobileLayout';
import Dashboard from './pages/Dashboard';
import MobileDashboard from './pages/MobileDashboard';
import './App.css';
import { useAuth0 } from '@auth0/auth0-react';

const App: React.FC = () => {
  const { isLoading, isAuthenticated, loginWithRedirect } = useAuth0();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      loginWithRedirect();
    }
  }, [isLoading, isAuthenticated, loginWithRedirect]);

  const LayoutComponent = isMobile ? MobileLayout : Layout;
  const DashboardComponent = isMobile ? MobileDashboard : Dashboard;

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!isAuthenticated) {
    // You can return a different loading indicator here if you prefer
    return <div>Redirecting to login...</div>;
  }

  return (
    <Router>
      <LayoutComponent>
        <Routes>
          <Route path="/" element={<DashboardComponent />} />
          {/* Add more routes here as needed */}
        </Routes>
      </LayoutComponent>
    </Router>
  );
};

export default App;
